import React from 'react';
import Layout from '../components/layout/layout';
import * as styles from '../components/blog.module.css';
import { graphql, Link } from 'gatsby'
import Image from "gatsby-image"
import styled from "styled-components"

export const query = graphql
`
    query SITE_INDEX_QUERY {
        site {
            siteMetadata {
               title
               description
            }
        }
        allMdx(
            sort: {fields: [frontmatter___date], order: DESC},
            filter: {frontmatter: {published: {eq: true}, featured: {eq: false}}}
        ){
            nodes {
                id
                excerpt(pruneLength: 250)
                frontmatter {
                    title
                    date
                    postimage {
                        childImageSharp {
                            fluid(maxWidth: 800) {
                              ...GatsbyImageSharpFluid_noBase64
                            }
                        }  
                    }
                }
                fields {
                    slug
                }
            }
        }
    }
`
const BannerImage = styled(Image)`
  width: 100%;
  height:100%;
`

const Blog = ({data}) => {

  return(
    <Layout>
        <div className={styles.cardsRow1}>
          <div className={styles.newsDiv}>
            <h1 className={styles.newsheading}>News & Updates</h1>
            <p className={styles.newsdesc}>Check out our News and Latest Updates!</p>
          </div>
          <div className="row">
          {data.allMdx.nodes.map(({ excerpt, frontmatter, fields }) => (
            <> 
            {frontmatter.title.length> 60?
            <div className="col-lg-8" id={styles.whatDivCol1}>
              <div className={styles.propmesigns}>
                <div className={styles.partners1Img}>
                  {frontmatter.postimage && (
                    <BannerImage
                      fluid={frontmatter.postimage.childImageSharp.fluid}
                      alt="Banner Image"
                    />
                  )}
                </div>
                <div className={styles.contentsdiv}>
                <div className={styles.cardtext}>
                  <h2 className={styles.raceheading}>{frontmatter.title}</h2>
                  <text className={styles.partnerDesc}>{excerpt}</text>
                    <div className={styles.continueDiv}>
                    <a className={styles.servicelinks} href={fields.slug}><text className={styles.continuetext1}>CONTINUE READING{'>'}</text></a>
                    </div>
                </div>
              </div>
              </div>
            </div>
            : 
            <div className="col-lg-4" id={styles.whatDivCol}>
              <div className={styles.cardsRow1Col}>
                <div className={styles.partners1}>
                  <div className={styles.partners1Img}>
                    {frontmatter.postimage && (
                      <BannerImage
                        fluid={frontmatter.postimage.childImageSharp.fluid}
                        alt="Banner Image"
                      />
                    )}
                  </div>
                  <div className={styles.contentsdiv}>
                    <div className={styles.partners1headerMain}>
                      <text className={styles.partners1header}>
                        {frontmatter.title}
                      </text>
                    </div>
                    <text className={styles.partnerDesc}>{excerpt}</text>
                    <div className={styles.continueDiv}>
                    <a className={styles.servicelinks} href={fields.slug}><text className={styles.continuetext1}>CONTINUE READING{'>'}</text></a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            }
            </>
            ))}
          </div>
        </div>
      
    </Layout>
  )
}

export default Blog
